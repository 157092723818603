import React, {Component} from "react"
import styles from "./Footer.module.css"
import {School} from "components/School"

const states = [
    {
        name: "Miami Media School",
        url: "https://beonair.com/locations/miami/",
        campuses: [
            {
                location:
                    "https://www.google.com/maps/place/7955+NW+12th+St+%23119,+Doral,+FL+33126/@25.783494,-80.325619,16z/data=!4m5!3m4!1s0x88d9b9095f8715ff:0x57347f2be21556f0!8m2!3d25.7834944!4d-80.3256192?hl=en",
                direction1: "7955 NW 12 Street",
                direction2: "Suite 119",
                direction3: "Doral, FL 33126",
                phone: 3057281120,
                phoneText: "(305) 728-1120"
            }
        ]
    },
    {
        name: "Colorado Media School",
        url: "https://beonair.com/locations/colorado",
        campuses: [
            {
                location:
                    "https://www.google.com/maps/place/Colorado+Media+School/@39.70896,-105.077874,16z/data=!4m5!3m4!1s0x0:0x694f134865cd4764!8m2!3d39.7089604!4d-105.0778743?hl=en-US",
                direction1: "404 S. Upham Street",
                direction2: "Lakewood, CO 80226",
                direction3: "",
                phone: 3039377070,
                phoneText: "(303) 937-7070"
            }
        ]
    },
    {
        name: "Ohio Media School",
        url: "",
        campuses: [
            {
                url: "https://beonair.com/locations/cleveland",
                name: "Cleveland Campus",
                location:
                    "https://www.google.com/maps/place/Colorado+Media+School/@39.70896,-105.077874,16z/data=!4m5!3m4!1s0x0:0x694f134865cd4764!8m2!3d39.7089604!4d-105.0778743?hl=en-US",
                direction1: "9885 Rockside Road #160",
                direction2: "Valley View, OH 44125",
                phone: 2165035900,
                phoneText: "(216) 503-5900"
            },
            {
                url: "https://beonair.com/locations/cincinnati/",
                name: "Cincinnati Campus",
                location:
                    "https://www.google.com/maps?ll=39.157924,-84.458846&z=16&t=m&hl=en-US&gl=US&mapclient=embed&cid=10175526740175670781",
                direction1: "4411 Montgomery Road",
                direction2: "Suite 200",
                direction3: "Norwood, OH 45212",
                phone: 5132716060,
                phoneText: "(513) 271-6060"
            },
            {
                url: "https://beonair.com/locations/columbus/",
                name: "Columbus Campus",
                location:
                    "https://www.google.com/maps?ll=39.955226,-82.855333&z=16&t=m&hl=en-US&gl=US&mapclient=embed&cid=10006334534169045845",
                direction1: "5330 E Main St. Suite 200",
                direction2: "Columbus, OH 43213",
                phone: 6146555250,
                phoneText: "(614) 655-5250"
            }
        ]
    },
    {
        name: "Illinois Media School",
        url: "",
        campuses: [
            {
                url: "https://beonair.com/locations/lombard/",
                name: "Lombard Campus",
                location:
                    "https://www.google.com/maps/place/Illinois+Media+School/@41.840447,-88.025379,16z/data=!4m5!3m4!1s0x0:0xe87c523a01fbc12f!8m2!3d41.8404467!4d-88.0253791?hl=en-US",
                direction1: "455 Eisenhower Lane S. #200",
                direction2: "Lombard, IL, 60148",
                phone: 6309161700,
                phoneText: "(630) 916-1700"
            },
            {
                url: "https://beonair.com/locations/chicago/",
                name: "Chicago Campus",
                location:
                    "https://www.google.com/maps/place/Illinois+Media+School/@41.874951,-87.627961,16z/data=!4m5!3m4!1s0x0:0x7a0017fd845fb3a6!8m2!3d41.8749508!4d-87.6279613?hl=en-US",
                direction1: "105 W Adams St. Suite 1900",
                direction2: "Chicago, IL 60605",
                phone: 3312154762,
                phoneText: "(331) 215-4762"
            }
        ]
    }
]

interface Props {}

export class Footer extends Component<Props, {}> {
    public render() {
        return (
            <footer className={styles.root}>
                <div className={styles.wrapper}>
                    <p className={styles.text}>
                        <strong>BeOnAir Network</strong>
                    </p>
                    <div className={styles.schoolsContainer}>
                        {states.map((state, i) => (
                            <React.Fragment key={i + 1}>
                                <School school={state} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </footer>
        )
    }
}
